import React from 'react'
import { Link } from 'react-router-dom'
import {connectionUrl} from "../../helpers/constants/HeaderConstants";

/**
 * Содержимое страницы Faq Ситилинк
 *
 * @type {({descr: *[], title: string}|{descr: *[], title: string}|{descr: *[], title: string}|{descr: *[], title: string}|{descr: *[], title: string})[]}
 */
export const CitylinkItems = [
  {
    id:'house_camera_access',
    title: 'Мне нужны записи с камер, установленных на моем доме. У меня пока нет подключения к «Ситилинку». Как мне получить записи?',
    descr: [<div key={2}>
      <p>Записи с публичных камер доступны свободно для клиентов любого провайдера.</p>
      <p>Для получения записей с дворовых камер есть 3 способа:</p>
      <p>1. Проект функционирует на базе сети «Ситилинк», поэтому для доступа к архиву видеозаписей
        вам
        нужно подключение к «Ситилинку». <a rel="noopener noreferrer"
                                            href="http://in.citylink.pro/">Переключайтесь
          бесплатно</a>.</p>
      <p>2. Самостоятельно получите доступ к записям с камер по номеру мобильного телефона. <br/> Следуйте простой
        инструкции <a rel="noopener noreferrer"
                      href="/code">здесь</a>.</p>
      <p>3. Напишите заявление на предоставление доступа к архиву камеры и отправьте скан или фото
        заявления, а также скан или фото 2-й и 3-й страницы паспорта на
        help@citylink.pro. <br/> Скачайте <a rel="noopener noreferrer"
                                             href="/documents/zayavlenye_predostavlenie_dostupa.doc">форму
          для заполнения</a>.</p>
      <p>Напишите заявление на предоставление доступа к архиву камеры в офисе компании «Ситилинк» по
        адресу: Чапаева, 44.</p>
    </div>]
  },
  {
    id: 'mrsk_cameras',
    title: 'Как скачать архив с камеры Мурманска?',
    descr: [<div key={13}>
      <p><b>Как скачать архив с камеры Мурманска?</b></p>
      <ol style={{paddingLeft: 25 + 'px'}}>
        <li>Выбрите камеру и отрезок архива, который хотите скачать.</li>
        <li>Войдите в свою учетную запись или зарегистрируйтесь.</li>
        <li>Приобретите доступ к скачиванию архива с камеры.</li>
        <li>Скачайте нужные отрезки архива в течение 24 часов.</li>
      </ol>

      <p><b>Сколько действует доступ к скачиванию архива с камеры?</b></p>
      <p>В течение 24 часов, после прекращает свое действие.</p>

      <p><b>Я приобрел доступ, почему с других камер нельзя скачать архив?</b></p>
      <p>Доступ к скачиванию архива приобретается на каждую камеру отдельно.</p>

      <p><b>Почему не получается скачать архив, если приобрел доступ?</b></p>
      <ol style={{paddingLeft: 25 + 'px'}}>
        <li>У вас закончилось время действия доступа (24 часа истекли).</li>
        <li>Вы пытаетесь скачать архив с камеры, на которую доступ не приобретён.</li>
        <li>С вашей карты не прошло списание за доступ к скачиванию архива.</li>
      </ol>

      <p><b>Почему с моей карты нельзя приобрести доступ?</b></p>
      <p>Проверьте баланс карты или обратитесь в ваш банк.</p>

      <p><b>У вас остались вопросы или нужна помощь?</b></p>
      <p>Звоните 8 (921) 4-555-777, мы на связи 24/7</p>
    </div>]
  },
  {
    title: 'Я являюсь клиентом «Ситилинка», и мне нужны видеозаписи с камер дома, в котором я не проживаю. Что мне нужно сделать, чтобы их получить?',
    descr: [<div key={3}>
      <p>Для получения записей с дворовых камер есть 2 способа:</p>

      <p>1. Вы можете самостоятельно получить записи с нужных Вам камер по номеру телефона. <br/>
        Для этого перейдите по <a rel="noopener noreferrer"
                                  href="/code">ссылке</a> и следуйте простой инструкции.</p>
      <p>2. Вы можете написать заявление на предоставление доступа к архиву камеры и прислать скан или фото заявления, а
        также скан или фото 2-й и 3-й страницы паспорта на help@citylink.pro.<br/>
        Скачайте <a rel="noopener noreferrer"
                    href="/documents/zayavlenye_predostavlenie_dostupa.doc">форму
          для заполнения</a></p>

      <p>Или написать заявление на предоставление доступа к архиву камеры в офисе компании «Ситилинк» по адресу:
        Чапаева, 44.</p>

      {/*<p>Вам нужно написать заявление на предоставление доступа к архиву камеры и прислать скан или*/}
      {/*    фото*/}
      {/*    заявления, а также скан или фото 2-й и 3-й страницы паспорта на*/}
      {/*    help@citylink.pro. <br/> Скачайте <a rel="noopener noreferrer"*/}
      {/*                                         href="/documents/zayavlenye_predostavlenie_dostupa.doc">форму*/}
      {/*        для заполнения</a></p>*/}
      {/*<p>Или написать заявление на предоставление доступа к архиву камеры в офисе компании «Ситилинк»*/}
      {/*    по*/}
      {/*    адресу: Чапаева, 44.</p>*/}
    </div>]
  },
  {
    title: 'Cколько стоит установка камер видеонаблюдения? Когда установят?',
    descr: [<div key={4}>
      <p>Камеры устанавливаются и обслуживаются силами компании «Ситилинк». <a
          rel="noopener noreferrer"
          href={connectionUrl}>Узнайте
        подробнее</a> о стоимости установки и хранении записей.</p>
    </div>]
  },
  {
    title: 'Можно ли переставить камеры в другое место или поменять их ракурс?',
    descr: [<div key={5}>
      <p>Все изменения, связанные с местоположением <span className="bold-text">дворовых</span> камер
        или их ракурсом, предварительно согласовываются со старшим по дому или председателем ТСЖ.
      </p>
    </div>]
  },
  {
    title: 'Как оставить заявку на установку камер?',
    descr: [<div key={6}>
      <p>Подайте заявку на платную установку камер в вашем дворе <a rel="noopener noreferrer"
                                                                    href={connectionUrl}>прямо
        на сайте</a>. Или позвоните по телефону <a className="faq-phone" rel="noopener noreferrer"
                                                   href="tel:+79214555777">8 (921) 4-555-777</a>.</p>
    </div>]
  },
  {
    title: 'Не нарушают ли видеокамеры неприкосновенность частной жизни? (в соответствии с Конституцией)',
    descr: [<div key={7}>
      <p>Камеры видеонаблюдения расположены на фасадах зданий и снимают придомовую территорию, которая
        не попадает под действие статьи 24 Конституции РФ, ч. 1: «Сбор, хранение, использование и
        распространение информации о частной жизни лица без его согласия не допускаются».</p>
    </div>]
  },
  {
    title: 'Какие камеры вы устанавливаете?',
    descr: [<div key={8}>
      <p>Компания «Ситилинк» устанавливает цифровые камеры высокой чёткости с функцией детекции
        движения
        в дневное и ночное время.</p>
    </div>]
  },
  {
    title: 'Сколько энергии потребляют социальные камеры? Кто за нее будет платить?',
    descr: [<div key={9}>
      <p>Камеры потребляют 2 Вт·ч. Все расходы оплачиваются компанией «Ситилинк».</p>
    </div>]
  },
  {
    title: 'Что нужно для того, чтобы установить камеры на наш дом?',
    descr: [<div key={10}>
      <p>Проект «Мой Дом» функционирует на базе сети «Ситилинк». Поэтому для установки камер ваш дом
        должен быть подключён к «Ситилинку». Для установки камер на ваш дом <a
            rel="noopener noreferrer" href={connectionUrl}> подайте заявку на
          сайте</a> или позвоните по телефону <a className="faq-phone" rel="noopener noreferrer"
                                                 href="tel:+79214555777">8 (921) 4-555-777</a>.</p>
    </div>]
  },
  {
    title: 'Кто обслуживает социальные камеры?',
    descr: [<div key={11}>
      <p>Обслуживание социальных камер производится за счет средств и силами компании «Ситилинк».</p>
    </div>]
  },
  {
    id:'rules',
    title: 'Где размещены правила доступа к видеозаписям на сайте Мой Дом?',
    descr: [<div key={12}>
      <p><Link to={'/rules'}>Правила доступа</Link> к видеозаписям.</p>
    </div>]
  }
]
