import React from 'react'
import {Link, NavLink} from 'react-router-dom'
import {Clusterer, Map, Placemark, YMaps} from 'react-yandex-maps'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import {OKIcon, OKShareButton, TelegramIcon, TelegramShareButton, VKIcon, VKShareButton} from 'react-share'

import PublicPlayer from '../components/PublicPlayer'
import {CITYLINK, cityNameFormatted, currentProviderTitleFormatted, MOBILE} from '../helpers/constants/HeaderConstants'

import myIcon from '../images/camera.png'
import myIconActive from '../images/cameraActive.png'
import myIconCluster from '../images/cluster.png'

import '../sass/Public.css'
import PublicCamerasCitiesList from './PublicCameras/PublicCamerasCitiesList'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import PublicMapTags from "./Header/Metatags/PublicMapTags";
import Promo from './Promo';
import {mdApi} from "../index";
import {Component} from "react";
import {PUBLIC_MAP_PATH} from "../routes";
import NotFound from './NotFound'

const getPointData = camera => {
    return {
        iconContent: camera.id,
        id: camera.id,
        balloonContent: '<div id="ya-ballon"></div>',
        hasHint: 1,
        hintContent: camera.name,
    }
}

const getClusterOptions = () => {
    return {
        clusterIcons: [
            {
                href: myIconCluster,
                size: [40, 40],
                offset: [-20, -20]
            },
            {
                href: myIconCluster,
                size: [60, 60],
                offset: [-30, -30]
            }
        ],
        groupByCoordinates: false,
        hasBalloon: false,
        hasHint: false,
        maxZoom: 15
    }
}

const getPointOptions = () => {
    return {
        balloonLayout: 'default#imageWithContent',
        balloonShadow: false,
        balloonCloseButton: false,
        hideIconOnBalloonOpen: false,
        iconLayout: 'default#imageWithContent',
        iconImageHref: myIcon,
        iconImageSize: [28, 36],
        iconImageOffset: [-12, -36]
    }
}

window.timeout = null

class PublicMap extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selectedCity: undefined,
            cities: [],
            cameras: [],
            searchCameras: [],
            isLoading: true,
            citySwitch: false,
            isShare: false,
            mapState: {
                center: [61.7858, 34.361],
                zoom: 13,
                controls: []
            },
            mapOptions: {
                yandexMapDisablePoiInteractivity: true,
                suppressMapOpenBlock: true,
                autoFitToViewport: 'always',
                yandexMapAutoSwitch: false
            },
            isSuccessSearch: false,
            balloon: false,
            currentPoint: {
                id: null,
                img: null,//'https://s1.moidom-stream.ru/s/public/0000000089_360.jpg',
                incident: null,
                lat: null,
                lon: null,
                name: null,
                src: null//'https://s1.moidom-stream.ru/s/public/0000000089.m3u8'
            },
            notFound: false,
        }
    }

    generateKey = (pre) => {
        return `point_${pre}`
    }

    handleSearch = (search) => {
        let selectedCity = this.state.selectedCity
        if (search.length >= 3) {
            clearTimeout(window.timeout)

            window.timeout = setTimeout(() => {
                mdApi.publicCamerasByCityKey(selectedCity.key, search)
                    .then(cameras => {
                        this.setState({searchCameras: cameras})
                    })

                this.setState({
                    isSuccessSearch: true,
                })

            }, 400)
        } else {
            this.setState({
                isSuccessSearch: false,
            })
        }
    }

    handleCityList = () => {
        this.setState(prevState => ({
            citySwitch: !prevState.citySwitch
        }))
    }

    renderRacurs = () => {
        [].forEach.call(document.querySelectorAll('.rakurs'), function (e) {
            e.parentNode.removeChild(e)
        })

        const pointers = document.querySelectorAll('.ymaps-2-1-79-placemark-overlay')

        let id = 0

        let points = {};
        this.state.cameras.forEach(camera => {
            let cameraId = camera.id
            points[cameraId] = camera
        })

        pointers.forEach(pointer => {
            id = pointer.querySelectorAll('.ymaps-2-1-79-image-with-content-content')[0]

            if (id !== undefined)
                id = id.childNodes[0]
            if (id !== undefined)
                id = id.childNodes[0]
            if (id !== undefined)
                id = id.innerHTML
            if (id !== undefined) {
                let div = document.createElement('div')
                div.classList.add('rakurs')
                div.id = 'rakurs_' + id
                div.id = 'rakurs_' + id

                if (Object.keys(points).length !== 0) {
                    let point = points[id]
                    if (point !== undefined) {
                        let angle = point.angle
                        let hasAngle = angle !== undefined && angle !== null

                        if (hasAngle) {
                            div.style = `transform: rotate(${angle}deg);
                                         transform-origin: 50% 100%;
                                         width: 100px;
                                         height: 100px;
                                         margin-top: -100px;
                                         margin-left: -48px;`;
                        } else {
                            div.style = `height: 0; width: 0`;
                        }

                    }

                }
                pointer.appendChild(div)
            }
        })

    }

    handleApiAvaliable(ymaps) {
        let _state = this
        let _this = this

        ymaps.ready(() => {

            if (!this)
                return

            _state.setState({
                isLoading: false,
            })

            let _this = this

            document.addEventListener(
                'click',
                function (event) {
                    if (event.target.matches('#balloon_close')) {
                        if (_this.map)
                            _this.map.balloon.close()
                    } else if (event.target.matches('#point_rakurs')) {
                        let id = 0

                        if (event.target.dataset !== undefined) {
                            id = event.target.dataset.id
                        }

                        let lat = event.target.dataset.lat
                        let lon = event.target.dataset.lon

                        if (_this.map) {
                            _this.map.setCenter([lat, lon], 16)
                        } else {
                            return
                        }

                        setTimeout(function () {
                            _state.renderRacurs()
                        }, 300)

                        setTimeout(
                            function () {
                                let rakurs = false

                                if (id) {
                                    rakurs = document.getElementById('rakurs_' + id)
                                }

                                if (rakurs) {
                                    rakurs.classList.add('active')
                                }
                            },
                            300
                        )

                    } else if (event.target.matches('#zoom-in')) {
                        if (_this.map) {
                            let zoom = _this.map.getZoom()
                            _this.map.setZoom(zoom + 1)
                        }
                    } else if (event.target.matches('#zoom-out')) {
                        if (_this.map) {
                            let zoom = _this.map.getZoom()
                            _this.map.setZoom(zoom - 1)
                        }
                    } else if (event.target.matches('#zoom-rakurs')) {
                        if (_this.map) {
                            _this.map.setCenter(_state.state.mapState.center, 15)
                            setTimeout(function () {
                                _state.renderRacurs()
                            }, 300)
                        }
                    } else if (event.target.matches('.items-search__item-link')) {
                        let id = false

                        if (event.target.dataset !== undefined) {
                            id = event.target.dataset.id
                        }

                        let center = [event.target.dataset.lat, event.target.dataset.lon]

                        if (_this.map) {
                            _this.map.setCenter(center, 16)
                        } else {
                            return false
                        }

                        _state.setState({
                            isSuccessSearch: false,
                        })

                        setTimeout(function () {
                            _this.map.geoObjects.each(function (e) {
                                let geo = e.getGeoObjects()
                                for (let i = 0, l = geo.length; i < l; i++) {
                                    geo[i].options.set('iconImageHref', myIcon)

                                    if (parseInt(geo[i].properties.get('id')) === parseInt(id)) {
                                        //let currentPoint = _state.props.points[id]
                                        let currentPoint = _this.state.cameras.find((camera) => {
                                            return parseInt(camera.id) === parseInt(id)
                                        })

                                        if (currentPoint === undefined) {
                                            continue;
                                        }

                                        _state.setState({
                                            currentPoint: currentPoint,
                                            balloon: true,
                                        })

                                        geo[i].balloon.open()
                                        geo[i].options.set('iconImageHref', myIconActive)
                                        let rakurs = document.getElementById('rakurs_' + id)

                                        if (rakurs)
                                            rakurs.classList.add('active')
                                    }
                                }
                            })
                        }, 300)

                    } else {
                        return null
                    }
                },
                false
            )

            if (this.map) {
                this.map.options.set('minZoom', 10)
                this.map.options.set('maxZoom', 18)

                this.map.geoObjects.events.add('click', function (e) {
                    _state.setState({
                        citySwitch: false,
                    })
                    _this.map.balloon.close()

                    setTimeout(
                        function () {
                            let targetId = e.get('target').properties.get('id')
                            let rakurs = false

                            if (targetId) {
                                rakurs = document.getElementById('rakurs_' + targetId)
                                //let currentPoint = _state.props.points[targetId]
                                let currentPoint = _this.state.cameras.find((camera) => {
                                    return camera.id === targetId
                                })

                                if (currentPoint === undefined) {
                                    return
                                }

                                _state.setState({
                                    currentPoint: currentPoint,
                                    balloon: true
                                })

                                e.get('target').options.set('iconImageHref', myIconActive)
                            }

                            if (rakurs) {
                                rakurs.classList.add('active')
                            }
                        },
                        200
                    )
                })

                this.map.events
                    .add('click', function (e) {
                        _state.setState({
                            citySwitch: false,
                        })
                    })
                    .add('balloonclose', function (e) {
                        // скрываем shareLinks
                        _state.setState({
                            isShare: false,
                        })

                        _this.map.geoObjects.each(function (e) {
                            let geo = e.getGeoObjects()
                            for (let i = 0, l = geo.length; i < l; i++) {
                                geo[i].options.set('iconImageHref', myIcon)
                            }

                            let rakurses = document.getElementsByClassName('rakurs active')
                            if (rakurses && rakurses[0])
                                rakurses[0].classList.remove('active')

                            _state.setState({
                                balloon: false,
                            })
                        })
                    })
                    .add('boundschange', function (e) {
                        let newZoom = e.get('newZoom'), oldZoom = e.get('oldZoom')
                        if (newZoom !== oldZoom || newZoom === oldZoom)
                            if (newZoom >= 15)
                                setTimeout(function () {
                                    _state.renderRacurs()
                                }, 300)
                            else
                                [].forEach.call(document.querySelectorAll('.rakurs'), function (e) {
                                    e.parentNode.removeChild(e)
                                })
                    })
            }
        })
    }

    /**
     * Поделиться камерой - копирование ссылки в буфер обмена
     *
     * @returns {boolean}
     */
    handleShare = () => {
        this.setState({
            isShare: !this.state.isShare
        })
    }

    /**
     * Копировать ссылку в буфер обмена
     */
    handleCopyShareLink = () => {
        navigator.clipboard.writeText(
            window.location.origin + '/pub/' + this.state.currentPoint.id
        ).then(function () {
            /* clipboard successfully set */
            alert('Ссылка скопирована')
            return true
        }, function () {
            /* clipboard write failed */
            alert('Не удалось скопировать ссылку')
            return false
        })
    }

    setMapControlInstanceRef = ref => {
        this.map = ref
    }

    fetchCities = () => {
        return mdApi.cities()
    }

    updateCities = () => {
        return this.fetchCities()
            .then(cities => {
                this.setState({
                    cities
                })
            })
    }

    fetchCameras = (cityKey) => {
        return mdApi.publicCamerasByCityKey(cityKey)
    }

    updateCameras = () => {
        let selectedCity = this.state.selectedCity
        if (selectedCity === undefined) {
            return Promise.reject()
        }

        return this.fetchCameras(selectedCity.key)
            .then(cameras => {
                this.setState({
                    cameras
                })
            })
    }

    handleSelectedCity = selectedCity => {
        if (this.state.selectedCity === selectedCity) {
            return;
        }

        let publicCityKey = this.props.match.params.city

        this.setState({
            city: selectedCity.key,
            citySwitch: false,
            cityName: selectedCity.name,
            mapState: {
                center: [selectedCity.center.latitude, selectedCity.center.longitude],
                zoom: selectedCity.ymap_zoom,
                controls: []
            },
            selectedCity
        }, () => {
            this.updateCameras()
                .then(() => {
                    if(publicCityKey !== selectedCity.public_key){
                        this.props.history.push(`${PUBLIC_MAP_PATH}/${selectedCity.public_key}`)
                    }
                })
        })
    }

    handleButtonChange = (cityKey) => {
        let city = this.state.cities.find(city => city.key === cityKey)

        if(city === undefined){
            return
        }

        return this.handleSelectedCity(city)
    }

    componentDidMount() {
        let publicCityKey = this.props.match.params.city

        this.updateCities()
            .then(() => {
                let cities = this.state.cities
                let selectedCity = undefined

                if (publicCityKey === undefined) {
                    selectedCity = cities[0] ?? undefined
                    if (selectedCity !== undefined && publicCityKey !== selectedCity.public_key) {
                        let path = `${PUBLIC_MAP_PATH}/${selectedCity.public_key}`;
                        if(this.props.location) {
                            path += this.props.location.search;
                        }
                        this.props.history.push(path)
                    }
                } else {
                    selectedCity = cities.find(city => city.public_key === publicCityKey)
                }

                if (selectedCity !== undefined) {
                    this.handleSelectedCity(selectedCity)
                } else {
                    this.setState({notFound: true})
                }

            })
    }

    render() {
        if(this.state.notFound) {
            return <NotFound />
        }

        let selectedCity = this.state.selectedCity

        if (selectedCity === undefined) {
            return null
        }

        let points = this.state.cameras.reverse().map(camera => {
            let coordinates = [camera.latitude, camera.longitude]

            return <Placemark
                key={camera.id}
                geometry={{coordinates}}
                properties={getPointData(camera)}
                options={getPointOptions()}
            />
        })

        let searchItems = this.state.searchCameras.map(camera => {
            return <li className="items-search__item" key={camera.id}>
                <a href="# " data-id={camera.id} data-lat={camera.latitude}
                   data-lon={camera.longitude}
                   className="items-search__item-link">{camera.name}</a>
            </li>
        })

        let shareTitle = 'Публичная камера «' + this.state.currentPoint.name + '» в ' + cityNameFormatted(selectedCity.name)[1]
            + '! Смотрите трансляцию онлайн и записи с архива на «Мой Дом»'

        let shareUrl = 'https://' + window.location.hostname + '/pub/' + this.state.currentPoint.id

        return (
            <div>
                <PublicMapTags
                    city1={cityNameFormatted(selectedCity.name)[0]}
                    city2={cityNameFormatted(selectedCity.name)[1]}
                    titleProvider={currentProviderTitleFormatted()}
                    cityUrl={selectedCity.name}
                />
                <div className="content-wrapper wide map">
                    {
                        !this.state.isLoading &&
                        <div>
                            <div className="content-navigate">
                                <div className="content-navigate__city" onClick={() => this.handleCityList()}>
                                    {selectedCity.name}
                                </div>

                                {
                                    this.state.citySwitch &&
                                    <div className="content-navigate__hiddenList">

                                        <PublicCamerasCitiesList
                                            handleButtonChange={this.handleButtonChange}
                                        />
                                    </div>
                                }

                                <div className="content-navigate__search">
                                    <input id="search" onChange={({target: {value}}) => this.handleSearch(value)}
                                           autoComplete="off"
                                           placeholder="Искать камеру по названию или улице"/>
                                    <button className="search" onClick={() => this.handleSearch()}>Искать</button>
                                    {
                                        this.state.isSuccessSearch &&
                                        <div className="search-result">
                                            <ul>
                                                {searchItems}
                                            </ul>
                                        </div>
                                    }
                                </div>

                                <div className="content-navigate__switch">
                                    <NavLink
                                        to={`/${selectedCity.public_key}`}
                                        isActive={() => {
                                            if (window.location.pathname === "/" ||
                                                window.location.pathname === `/${selectedCity.public_key}`) {
                                                return true;
                                            }
                                        }
                                        }
                                    >
                                        На карте
                                    </NavLink>
                                    <NavLink to={`/publist/${selectedCity.public_key}`}>Список</NavLink>
                                </div>

                                {
                                    this.state.balloon &&
                                    <div
                                        className={`player-balloon ${(!CITYLINK && this.props.isCitylinkRegion) ? 'citylink_client' : ''}`}
                                        key={this.generateKey(this.state.currentPoint.id)}>
                                        <span id="balloon_close"/>
                                        <div className="balloon-head">
                                            <Link
                                                to={`/pub/${this.state.currentPoint.id}`}>{this.state.currentPoint.name}</Link>
                                        </div>
                                        <div className="balloon-player">
                                            <PublicPlayer width={'470'}
                                                          height={'264'}
                                                          source={this.state.currentPoint.src}
                                                          audio={false}
                                                          poster={this.state.currentPoint.img}
                                                          type={'application/x-mpegURL'}
                                                          isArchive={false}/>
                                        </div>

                                        <div className='balloon-links'>
                                            <div className="links">
                                                <Link to={`/pub/${this.state.currentPoint.id}/archive`}>Архив</Link>
                                                <Link to={`/pub/${this.state.currentPoint.id}/screen`}>Снимки</Link>

                                                {
                                                    this.state.currentPoint.incident !== 0 &&
                                                    <Link
                                                        to={`/public/videos/${this.state.currentPoint.id}`}>Происшествия</Link>
                                                }

                                                <span data-id={this.state.currentPoint.id}
                                                      data-lat={this.state.currentPoint.latitude}
                                                      data-lon={this.state.currentPoint.longitude} id="point_rakurs">Ракурс камеры</span>
                                            </div>

                                            <span className="balloon-links share_ico" onClick={this.handleShare}/>
                                        </div>

                                        <ReactCSSTransitionGroup
                                            component="div"
                                            className="share_buttons_wrapper"
                                            transitionName="share-btns"
                                            transitionAppear={true}
                                            transitionEnter={true}
                                            transitionLeave={true}
                                            transitionAppearTimeout={10}
                                            transitionLeaveTimeout={10}
                                            transitionEnterTimeout={10}
                                        >
                                            {
                                                this.state.isShare &&
                                                <div className="share_buttons">
                                                    <VKShareButton
                                                        url={shareUrl}
                                                        title={shareTitle}
                                                        image={this.state.currentPoint.img}
                                                        windowWidth={660}
                                                        windowHeight={460}
                                                        className="social-wrapper__item">
                                                        <VKIcon size={32} round/>
                                                    </VKShareButton>
                                                    <TelegramShareButton
                                                        url={shareUrl}
                                                        quote={shareTitle}
                                                        className="social-wrapper__item">
                                                        <TelegramIcon size={32} round/>
                                                    </TelegramShareButton>
                                                    <OKShareButton
                                                        url={shareUrl}
                                                        title={shareTitle}
                                                        image={this.state.currentPoint.img}
                                                        windowWidth={660}
                                                        windowHeight={460}
                                                        className="social-wrapper__item">
                                                        <OKIcon size={32} round/>
                                                    </OKShareButton>

                                                    <div className="copy-block">
                                                        <input type="text" defaultValue={shareUrl}/>
                                                        <button className="copt-btn"
                                                                onClick={this.handleCopyShareLink}>копировать
                                                        </button>
                                                    </div>
                                                </div>
                                            }
                                        </ReactCSSTransitionGroup>
                                        {
                                            CITYLINK &&
                                            <div style={{margin: '0 20px'}}><Promo type='map'/></div>
                                        }
                                    </div>
                                }

                                <div className='content-navigate__tools'>
                                    <div className="tool" id='zoom-in'/>
                                    <div className="tool" id="zoom-out"/>
                                    <div className="tool" id='zoom-rakurs'/>
                                </div>
                            </div>
                        </div>
                    }

                    <div className="yandex-map">
                        <YMaps onApiAvaliable={(ymaps) => this.handleApiAvaliable(ymaps)} version="2.1">
                            <Map
                                width={document.body.clientWidth}
                                height={'800px'}
                                state={this.state.mapState}
                                instanceRef={this.setMapControlInstanceRef}
                                options={this.state.mapOptions}>
                                <Clusterer
                                    options={getClusterOptions()}>
                                    {points}
                                </Clusterer>
                            </Map>
                        </YMaps>
                    </div>
                    {
                        this.state.isLoading &&
                        <div className="loading map-loading"/>
                    }

                </div>
            </div>
        )
    }
}

export default PublicMap
